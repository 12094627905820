import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper\" data-test-wawi-box-content>\n  <div local-class=\"content\">\n\n  </div>\n\n  <Layout::FormActions local-class=\"actions\">\n    <Ui::Button @onClick={{this.onCancel}} data-test-wawi-box-cancel>\n      {{t \"wawiBox.action.cancel\"}}\n    </Ui::Button>\n    <Ui::Button @onClick={{this.onSave}} @style=\"primary\" data-test-wawi-box-submit>\n      {{t \"wawiBox.action.submit\"}}\n    </Ui::Button>\n  </Layout::FormActions>\n</div>", {"contents":"<div local-class=\"wrapper\" data-test-wawi-box-content>\n  <div local-class=\"content\">\n\n  </div>\n\n  <Layout::FormActions local-class=\"actions\">\n    <Ui::Button @onClick={{this.onCancel}} data-test-wawi-box-cancel>\n      {{t \"wawiBox.action.cancel\"}}\n    </Ui::Button>\n    <Ui::Button @onClick={{this.onSave}} @style=\"primary\" data-test-wawi-box-submit>\n      {{t \"wawiBox.action.submit\"}}\n    </Ui::Button>\n  </Layout::FormActions>\n</div>","moduleName":"@mvb/tix-ui/components/wawi-box/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/wawi-box/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';

export default class WawiBoxComponent extends Component {
  @service api;
  @service errors;

  @action
  onCancel() {
    this.args.onClose();
  }

  @action
  onSave() {
    this.saveTask.perform();
  }

  @task
  *saveTask() {
    try {
      yield this.api.postJSON(`/erp-referenced-products/${this.args.product.id}`, {
        referencedProductIds: [], // TODO #38360 replace with actual list of referenced product ids
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
